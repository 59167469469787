import { HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { isUndefined, startsWith } from "lodash";
import { AuthenticationService } from "src/app/infrastructure/authentication.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private authenticationService: AuthenticationService;
    private externalUrl = 'https://oauth.smartschool.be';

    public constructor(authenticationService: AuthenticationService) {
        this.authenticationService = authenticationService;
    }

    public intercept(request: HttpRequest<any>, next: HttpHandler) {

        const authToken = this.authenticationService.getAuthorizationToken();

        if(!isUndefined(authToken) && !startsWith(request.url, this.externalUrl)) {
            console.log(request);
            request = request.clone({
                headers: request.headers.set('Authorization', authToken)
            });
        }
        return next.handle(request);
    }
}