import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { isEqual } from 'lodash';
import { AppEvent } from './domain/app-event';
import { AppEventType } from './domain/app-event-type';
import { User } from './domain/user';
import { UserRole } from './domain/user-role.enum';
import { AuthenticationService } from './infrastructure/authentication.service';
import { EventQueueService } from './infrastructure/event-queue.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  private readonly location: Location;
  private readonly eventQueue: EventQueueService;
  private readonly authenticationService: AuthenticationService;
  public user: User;

  public constructor(
    location: Location,
    eventQueue: EventQueueService,
    authenticationService: AuthenticationService
    ) {
    this.location = location;
    this.eventQueue = eventQueue;
    this.authenticationService = authenticationService;
  }

  public ngOnInit(): void {
    this.loadUser();
    this.eventQueue.on(AppEventType.UserUpdated).subscribe((appEvent: AppEvent<any>) => {
      this.user = <User><any>appEvent.payload;
    });
  }

  public isStudent(): boolean {
    return isEqual(this.user.basisrol, UserRole.Leerling);
  }

  public isAdmin(): boolean {
    return isEqual(this.user.basisrol, UserRole.Administrator);
  }

  public isActive(isHome: boolean, path?: string): boolean {
    return isHome ? isEqual(this.location.path(), '') : this.location.path().includes(<string>path);
  }

  public logout(): void {
    this.authenticationService.logout();
  }

  private loadUser(): void {
    this.user = <User>this.authenticationService.getAuthenticatedUser();    
  }
}
