import { UserRole } from "./user-role.enum";

export class User {
    private _name: string;
    private _surname: string;
    private _basisrol: UserRole;
    private _username: string;
    private _officialClass: string;

    public constructor(name: string, surname: string, basisrol: UserRole, username: string, officialClass: string) {
        this._name = name;
        this._surname = surname;
        this._basisrol = basisrol;
        this._username = username;
        this._officialClass = officialClass;
    }

    public get name(): string {
        return this._name;
    }

    public set name(name: string) {
        this._name = name;
    }

    public get surname(): string {
        return this._surname;
    }

    public set surname(surname: string) {
        this._username = surname;
    }

    public get basisrol(): UserRole {
        return this._basisrol;
    }

    public set basisrol(basisrol: UserRole) {
        this._basisrol = basisrol;
    }

    public get username(): string {
        return this._username;
    }

    public set username(username: string) {
        this._username = username;
    }

    public get officialClass(): string {
        return this._officialClass;
    }

    public set officialClass(officialClass: string) {
        this._officialClass = officialClass;
    }

    public getFullName(): string {
        return this._name + ' ' + this._surname;
    }

    public static fromJSON(userJSON: any): User {
        return new User(
            userJSON.name, 
            userJSON.surname, 
            userJSON.basisrol, 
            userJSON.username,
            userJSON.officialClass
        );
    }
    
    public toJSON(): JSON {
        return <JSON><any>{
            name: this._name, 
            surname: this._surname, 
            basisrol: this._basisrol, 
            username: this.username,
            officialClass: this._officialClass
        }
    }
}