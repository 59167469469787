import { Meal } from "./meal.enum";

export class StudyDay {
    private _id: number;
    private _date: Date;
    private _signedUpForStudy: boolean;
    private _chosenMeal: Meal;

    public constructor(id: number, date: Date, signedUpForStudy: boolean, chosenMeal: Meal) {
        this._id = id;
        this._date = date;
        this._signedUpForStudy = signedUpForStudy;
        this._chosenMeal = chosenMeal;
    }

    public get id(): number {
        return this._id;
    }

    public get date(): Date {
        return this._date;
    }

    public set date(date: Date) {
        this._date = date;
    }

    public get signedUpForStudy(): boolean {
        return this._signedUpForStudy;
    }

    public set signedUpForStudy(signedUpForStudy: boolean) {
        this._signedUpForStudy = signedUpForStudy;
    }

    public get chosenMeal(): Meal {
        return this._chosenMeal;
    }

    public set chosenMeal(chosenMeal: Meal) {
        this._chosenMeal = chosenMeal;
    }

    public static fromJSON(studyDayJSON: any): StudyDay {
        return new StudyDay(
            studyDayJSON.id,
            new Date(studyDayJSON.date),
            studyDayJSON.signedUpForStudy,
            studyDayJSON.chosenMeal || Meal.HOME
        );
    }
}