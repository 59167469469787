import { Pipe, PipeTransform } from '@angular/core';
import { join, map, split, trim } from 'lodash';

@Pipe({
    name: 'getInitials'
})

export class GetInitialsPipe implements PipeTransform {
  transform(value: string) {
    const names = split(value, ' ');
    return join(map(names, (name: string) => (name.substring(0, 1))), '');
  }
}