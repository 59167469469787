import { Observable } from "rxjs";
import { InjectionToken } from "@angular/core";
import { StudyDay } from "src/app/domain/study-day";

export const STUDY_DAYS = new InjectionToken<StudyDays>('StudyDays');

export interface StudyDays {
    getStudyDays(): Observable<StudyDay[]>;
    updateStudyDay(studyDay: StudyDay): Observable<void>;
    downloadStudyPlanningExcel(): Observable<any>;
}