import { Injectable } from "@angular/core";
import { PersistentStore } from "./persistent-store";
import { CookieOptions, CookieService } from "ngx-cookie";
import { isNil } from "lodash";

@Injectable()
export class PersistentStoreService implements PersistentStore {
    private cookieService: CookieService;

    public constructor(cookieService: CookieService) {
        this.cookieService = cookieService;
    }

    public get(key: string): string | undefined {
        return this.cookieService.get(key);
    }

    public set(key: string, value: string, expires?: string): void {
        const options: CookieOptions = {};
        if (!isNil(expires)) {
            options.expires = expires;
        }
        this.cookieService.put(key, value, options);
    }

    public remove(key: string): void {
        this.cookieService.remove(key);
    }

    public getAll(): Object {
        return this.cookieService.getAll();
    }
}