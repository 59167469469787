import { Component, Inject } from '@angular/core';
import { StudyDays, STUDY_DAYS } from 'src/app/infrastructure/study-days/study-days';
import { BaseComponent } from '../components/base.component';
import { saveAs } from 'file-saver';

@Component({
  selector: 'zdc-study-planner-admin',
  templateUrl: './study-planner-admin.component.html',
  styleUrls: ['./study-planner-admin.component.scss']
})
export class StudyPlannerAdminComponent extends BaseComponent {
  private studyDays: StudyDays;

  public constructor(
    @Inject(STUDY_DAYS) studyDays: StudyDays
  ) {
    super();

    this.studyDays = studyDays;
  }

  public downloadStudyplanning(): void {
    this.studyDays.downloadStudyPlanningExcel()
    .subscribe((data) => {
      let fileBlob = data;
      let blob = new Blob([fileBlob], {
        type: "application/vnd.ms-excel"
      });

      //use file saver npm package for saving blob to file
      saveAs(blob, `Studieplanning.xlsx`);
    });
  }
}
