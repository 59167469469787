import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

// @ts-ignore
import { Buffer as NodeBuffer } from 'buffer';

window.Buffer = window.Buffer || NodeBuffer;

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
