import { Directive, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";

@Directive()
export abstract class BaseComponent implements OnDestroy {
    protected unSubscribeOnViewDestroy: Subject<boolean> = new Subject<boolean>();

    public ngOnDestroy(): void {
        this.unSubscribeOnViewDestroy.next(true);
        this.unSubscribeOnViewDestroy.complete();
    }
}