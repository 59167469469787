<h2 mat-dialog-title>{{studyDay.date | date: 'dd/MM/yyyy'}}</h2>
<mat-dialog-content class="mat-typography" [formGroup]="studyDayForm">
    <mat-checkbox class="example-margin"
        formControlName="signedUpForStudy">
        Ingeschreven voor studie
    </mat-checkbox>
    <h3>Maaltijd</h3>
    <mat-radio-group formControlName="chosenMeal" id="meal-radio-group">
      <mat-radio-button [value]="Meal.SCHOOL_HOT_MEAL">Warm op school</mat-radio-button>
      <mat-radio-button [value]="Meal.SCHOOL_SANDWICHES">Boterhammen op school</mat-radio-button>
      <mat-radio-button [value]="Meal.HOME">Thuis</mat-radio-button>
    </mat-radio-group>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="close()">Sluiten</button>
</mat-dialog-actions>