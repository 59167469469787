import { Inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { isUndefined } from "lodash";
import { Observable } from "rxjs";
import { PersistentStore, PERSISTENT_STORE } from "../helper/persistent-store";

@Injectable()
export class CanActivateAdminViaAuthGuard implements CanActivate {
    private readonly persistentStore: PersistentStore;
    private readonly router: Router;

    public constructor(
        @Inject(PERSISTENT_STORE) persistentStore: PersistentStore,
        router: Router
    ) {
        this.persistentStore = persistentStore;
        this.router = router;
    }

    public canActivate(): boolean {
        const hasUserReference = !isUndefined(this.persistentStore.get('user'));

        if(!hasUserReference) {
            this.router.navigateByUrl('/login-as-admin');
        }

        return hasUserReference;
    }
}