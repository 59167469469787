import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanActivateAdminViaAuthGuard } from './application/security/can-activate-admin-via-auth-guard';
import { CanActivateViaAuthGuard } from './application/security/can-activate-via-auth-guard';
import { AdminComponent } from './presentation/admin/admin.component';
import { LoginAsAdminComponent } from './presentation/login-as-admin/login-as-admin.component';
import { LoginComponent } from './presentation/login/login.component';
import { StudyPlannerAdminComponent } from './presentation/study-planner-admin/study-planner-admin.component';
import { StudyPlannerComponent } from './presentation/study-planner/study-planner.component';

const routes: Routes = [
  {path: '', component: StudyPlannerComponent, canActivate: [CanActivateViaAuthGuard]},
  {path: 'login-as-admin', component: LoginAsAdminComponent},
  {path: 'admin', component: AdminComponent, canActivate: [CanActivateAdminViaAuthGuard]},
  {path: 'study-planner-admin', component: StudyPlannerAdminComponent, canActivate: [CanActivateAdminViaAuthGuard]},
  {path: 'login', component: LoginComponent}
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
