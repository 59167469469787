import { Component, Inject } from '@angular/core';
import { takeUntil } from 'rxjs';
import { StudyDay } from 'src/app/domain/study-day';
import { BaseComponent } from '../components/base.component';
import { OAuthService } from "angular-oauth2-oidc";
import { StudyDays, STUDY_DAYS } from 'src/app/infrastructure/study-days/study-days';
import { AuthenticationService } from 'src/app/infrastructure/authentication.service';
import { User } from 'src/app/domain/user';
import { isEqual } from 'lodash';

@Component({
  templateUrl: './study-planner.component.html',
  styleUrls: ['./study-planner.component.scss']
})
export class StudyPlannerComponent extends BaseComponent {
  public listOfStudyDays: StudyDay[] = [];
  private user: User;
  private studyDays: StudyDays;
  private oauthService: OAuthService;
  private readonly authenticationService: AuthenticationService;

  public constructor(
    @Inject(STUDY_DAYS) studyDays: StudyDays,
    oauthService: OAuthService,
    authenticationService: AuthenticationService
  ) {
    super();
    this.studyDays = studyDays;
    this.oauthService = oauthService;
    this.authenticationService = authenticationService;
  }

  public ngOnInit(): void {
    this.getStudyDays();
    this.loadUser();
  }

  public logout(): void {
    this.oauthService.logOut();
  }

  public isStudent(): boolean {
    return isEqual(this.user?.basisrol, 'Leerling');
  }

  private loadUser(): void {
    this.user = <User>this.authenticationService.getAuthenticatedUser();    
  }

  private getStudyDays(): void {
    this.studyDays.getStudyDays()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe({
        next: (listOfStudyDays: StudyDay[]) => {
          this.listOfStudyDays = listOfStudyDays;
        },
        error: (error) => {
          console.log('err', error);
        }
      });
  }
}
