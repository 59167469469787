<article class="body">
  <section id="side-bar" *ngIf="user">
    <section id="side-bar-header">
      <h3>Plan <span class="highlighted-text">Sterre</span></h3>
      <div id="user-content">
        <div id="user-logo">
          <span>{{user.getFullName() | getInitials}}</span>
        </div>
        <div id="user-details">
          <span id="user-details-name">
            {{user.name}} {{user.surname}}
          </span>
          <span id="user-details-class">
            {{user.officialClass}}
          </span>
        </div>
      </div>
    </section>
    <mat-list id="navigation" *ngIf="isStudent()">
      <mat-list-item [routerLink]="''" [class.active]="isActive(true)">
        <span mat-line class="menu-item antialiased">Studieplanner</span>
      </mat-list-item>
    </mat-list>
    <mat-list id="navigation" *ngIf="isAdmin()">
      <mat-list-item [routerLink]="'/study-planner-admin'" [class.active]="isActive(false, '/study-planner-admin')">
        <span mat-line class="menu-item antialiased">Studieplanner</span>
      </mat-list-item>
    </mat-list>
    <section id="logout">
      <button mat-button color="primary" (click)="logout()">Uitloggen</button>
    </section>
  </section>
  <section id="main-content" [ngClass]="{'authenticated': user}">
    <router-outlet></router-outlet>
  </section>
</article>