import { Injectable } from "@angular/core";
import { isEqual } from "lodash";
import { filter, Observable, Subject } from "rxjs";
import { AppEvent } from "../domain/app-event";
import { AppEventType } from "../domain/app-event-type";

@Injectable()
export class EventQueueService {

  private eventBrocker = new Subject<AppEvent<any>>();

  public on(eventType: AppEventType): Observable<AppEvent<any>> {
    return this.eventBrocker.pipe(filter(event => isEqual(event.type,eventType)));
  }

  public dispatch<T>(event: AppEvent<T>): void {
    this.eventBrocker.next(event);
  }

}