import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { Navigation, Router } from '@angular/router';
import { OAuthService, TokenResponse } from 'angular-oauth2-oidc';
import { isNil } from 'lodash';
import { PersistentStore, PERSISTENT_STORE } from 'src/app/application/helper/persistent-store';
import { AppEvent } from 'src/app/domain/app-event';
import { AppEventType } from 'src/app/domain/app-event-type';
import { User } from 'src/app/domain/user';
import { AuthenticationService } from 'src/app/infrastructure/authentication.service';
import { EventQueueService } from 'src/app/infrastructure/event-queue.service';
import { BaseComponent } from '../components/base.component';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BaseComponent {
  private readonly router: Router;
  private readonly oauthService: OAuthService;
  private readonly httpClient: HttpClient;
  private readonly persistentStore: PersistentStore;
  private readonly eventQueue: EventQueueService;

  public constructor(
    router: Router,
    oauthService: OAuthService,
    httpClient: HttpClient,
    @Inject(PERSISTENT_STORE) persistentStore: PersistentStore,
    eventQueue: EventQueueService
  ) {
    super();
    
    this.router = router;
    this.oauthService = oauthService;
    this.httpClient = httpClient;
    this.persistentStore = persistentStore;
    this.eventQueue = eventQueue;
  }

  public login(): void {
    const navigation: Navigation | null = this.router.getCurrentNavigation();
    const authorizationCode = !isNil(navigation) && !isNil(navigation.extractedUrl) ? navigation.extractedUrl.queryParams['code'] : null;

    this.configureAuthFlowConfig();

    if (isNil(authorizationCode)) {
        this.oauthService.initCodeFlow();
    }
  }

  private configureAuthFlowConfig(): void {
      this.oauthService.configure(AuthenticationService.authConfig);
  }
}
