import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StudyPlannerComponent } from './presentation/study-planner/study-planner.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatListModule } from '@angular/material/list';
import { MatInputModule } from '@angular/material/input';
import { DayWrapperComponent } from './presentation/components/day-wrapper/day-wrapper.component';
import { StudyDayDetailComponent } from './presentation/components/study-day-detail/study-day-detail.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CanActivateViaAuthGuard } from './application/security/can-activate-via-auth-guard';
import {CookieModule} from 'ngx-cookie';
import { PERSISTENT_STORE } from './application/helper/persistent-store';
import { PersistentStoreService } from './application/helper/persistent-store.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { OAuthModule } from 'angular-oauth2-oidc';
import { GetInitialsPipe } from './application/pipes/getinitials.pipe';
import { EventQueueService } from './infrastructure/event-queue.service';
import { AuthenticationService } from './infrastructure/authentication.service';
import { HttpStudyDaysService } from './infrastructure/study-days/http-study-days.service';
import { STUDY_DAYS } from './infrastructure/study-days/study-days';
import { LoginAsAdminComponent } from './presentation/login-as-admin/login-as-admin.component';
import { LoginComponent } from './presentation/login/login.component';
import { AdminComponent } from './presentation/admin/admin.component';
import { CanActivateAdminViaAuthGuard } from './application/security/can-activate-admin-via-auth-guard';
import { StudyPlannerAdminComponent } from './presentation/study-planner-admin/study-planner-admin.component';
import { AuthInterceptor } from './application/http-interceptors/auth-interceptor';

@NgModule({
  declarations: [
    AppComponent,
    StudyPlannerComponent,
    DayWrapperComponent,
    StudyDayDetailComponent,
    GetInitialsPipe,
    LoginAsAdminComponent,
    LoginComponent,
    AdminComponent,
    StudyPlannerAdminComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatDialogModule,
    MatButtonModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatListModule,
    MatInputModule,
    CookieModule.withOptions(),
    HttpClientModule,
    OAuthModule.forRoot(),
    MatRadioModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    CanActivateViaAuthGuard,
    CanActivateAdminViaAuthGuard,
    {provide: STUDY_DAYS, useClass: HttpStudyDaysService},
    {provide: PERSISTENT_STORE, useClass: PersistentStoreService},
    EventQueueService,
    AuthenticationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
