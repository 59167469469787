import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { PersistentStore, PERSISTENT_STORE } from 'src/app/application/helper/persistent-store';
import { AppEvent } from 'src/app/domain/app-event';
import { AppEventType } from 'src/app/domain/app-event-type';
import { User } from 'src/app/domain/user';
import { UserRole } from 'src/app/domain/user-role.enum';
import { AuthenticationService } from 'src/app/infrastructure/authentication.service';
import { EventQueueService } from 'src/app/infrastructure/event-queue.service';
import { BaseComponent } from '../components/base.component';

@Component({
  templateUrl: './login-as-admin.component.html',
  styleUrls: ['./login-as-admin.component.scss']
})
export class LoginAsAdminComponent extends BaseComponent implements OnInit {
  public loginForm: FormGroup;
  public loginInvalid = false;
  private readonly formBuilder: FormBuilder;
  private readonly authentication: AuthenticationService;
  private readonly router: Router;
  private readonly persistentStore: PersistentStore;
  private readonly eventQueue: EventQueueService;

  public constructor(
    formBuilder: FormBuilder,
    authentication: AuthenticationService,
    router: Router,
    @Inject(PERSISTENT_STORE) persistentStore: PersistentStore,
    eventQueue: EventQueueService
  ) {
    super();

    this.formBuilder = formBuilder;
    this.authentication = authentication;
    this.router = router;
    this.persistentStore = persistentStore;
    this.eventQueue = eventQueue;
  }

  public ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  public login(): void {
    if(this.loginForm.valid) {
      this.loginInvalid = false;
      this.authentication.authenticateAsAdmin(this.loginForm.get('username')?.value, this.loginForm.get('password')?.value)
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe({
        next: () => {
          const user: User = new User('Admin', '', UserRole.Administrator, 'admin', '');
          this.persistentStore.set('user', JSON.stringify(user.toJSON()));
          this.eventQueue.dispatch(new AppEvent(AppEventType.UserUpdated, user));
          this.router.navigateByUrl('/admin');
        },
        error: (error) => {
          this.loginInvalid = true;
        }
      });
    }
    
  }
}
