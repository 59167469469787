<section id="login-content">
    <figure>
        <img id="logo" src="assets/images/logo.svg"/>
    </figure>
    

    <div id="login-button">
        <button mat-raised-button color="primary" (click)="login()">Inloggen met Smartschool</button>
    </div>
   
</section>