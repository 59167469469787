import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { cloneDeep } from 'lodash';
import { Meal } from 'src/app/domain/meal.enum';
import { StudyDay } from 'src/app/domain/study-day';
import { BaseComponent } from '../base.component';

@Component({
  templateUrl: './study-day-detail.component.html',
  styleUrls: ['./study-day-detail.component.scss']
})
export class StudyDayDetailComponent extends BaseComponent implements OnInit {
  public studyDay: StudyDay;
  public dialogRef: MatDialogRef<StudyDayDetailComponent>;
  public studyDayForm: FormGroup;
  public Meal = Meal;
  private readonly formBuilder: FormBuilder;
  private readonly triggerElementRef: ElementRef;

  public constructor(
    @Inject(MAT_DIALOG_DATA) data: {studyDay: StudyDay, trigger: ElementRef},
    dialogRef: MatDialogRef<StudyDayDetailComponent>,
    formBuilder: FormBuilder
  ) {
    super();
    
    this.studyDay = data.studyDay;
    this.dialogRef = dialogRef;
    this.formBuilder = formBuilder;
    this.triggerElementRef = data.trigger;
  }

  public ngOnInit(): void {
    this.studyDayForm = this.formBuilder.group({
      signedUpForStudy: this.studyDay.signedUpForStudy,
      chosenMeal: this.studyDay.chosenMeal
    });

    this.positionDialog();

  }

  public close(): void {
    const studyDay = cloneDeep(this.studyDay);
    studyDay.signedUpForStudy = this.studyDayForm.get('signedUpForStudy')?.value;
    studyDay.chosenMeal = this.studyDayForm.get('chosenMeal')?.value;
    this.dialogRef.close(studyDay);
  }

  private positionDialog(): void {
    const matDialogConfig: MatDialogConfig = new MatDialogConfig();
    const rect = this.triggerElementRef.nativeElement.getBoundingClientRect();
    matDialogConfig.position = { left: `${rect.left + 0}px`, top: `${rect.bottom}px` };
    this.dialogRef.updateSize(matDialogConfig.width, matDialogConfig.height);
    this.dialogRef.updatePosition(matDialogConfig.position);
  }
}
