import { Component, ElementRef, HostListener, Inject, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { StudyDay } from 'src/app/domain/study-day';
import { BaseComponent } from '../base.component';
import { StudyDayDetailComponent } from '../study-day-detail/study-day-detail.component';
import { takeUntil } from 'rxjs';
import { isEqual, isUndefined } from 'lodash';
import { StudyDays, STUDY_DAYS } from 'src/app/infrastructure/study-days/study-days';


@Component({
  selector: 'zdc-day-wrapper',
  templateUrl: './day-wrapper.component.html',
  styleUrls: ['./day-wrapper.component.scss']
})
export class DayWrapperComponent extends BaseComponent {
  @Input() public studyDay: StudyDay;
  private dialog: MatDialog;
  private studyDays: StudyDays;

  public constructor(
    dialog: MatDialog,
    @Inject(STUDY_DAYS) studyDays: StudyDays
  ) {
    super();
    this.dialog = dialog;
    this.studyDays = studyDays;
  }

  @HostListener('click', ['$event'])
  public openStudyDayDetail (event: MouseEvent): void {
    const target = new ElementRef(event.currentTarget);
    this.dialog.open(StudyDayDetailComponent, {
      data: {
        studyDay: this.studyDay,
        trigger: target
      },
      panelClass: 'study-day-detail-modal'
    })
      .afterClosed()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((adjustedStudyDay: StudyDay) => {
        if(!isUndefined(adjustedStudyDay) && !isEqual(adjustedStudyDay, this.studyDay)) {
          this.studyDay = adjustedStudyDay;
          this.studyDays.updateStudyDay(adjustedStudyDay)
            .pipe(takeUntil(this.unSubscribeOnViewDestroy))
            .subscribe(() => {
              console.log('updated');
            });
        }
        
      });
  }
}
