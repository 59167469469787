import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "lodash";
import { Observable, of, map as mapRxjs } from "rxjs";
import { StudyDay } from "../../domain/study-day";
import { AuthenticationService } from "../authentication.service";
import { StudyDays } from "./study-days";
import { environment } from "src/environments/environment";

@Injectable()
export class HttpStudyDaysService implements StudyDays {
    private readonly httpClient: HttpClient;
    private readonly authenticationService: AuthenticationService;

    public constructor(
        httpClient: HttpClient,
        authenticationService: AuthenticationService
        ) {
        this.httpClient = httpClient;
        this.authenticationService = authenticationService;
    }
    public getStudyDays(): Observable<StudyDay[]> {
        const username: string = Buffer.from(<string>this.authenticationService.getAuthenticatedUser()?.username).toString('base64');
        return this.httpClient.get(`${environment.apiUrl}/users/${username}/studydays`)
        .pipe(mapRxjs((studyDaysJSON: any) => {
            return map(studyDaysJSON, (studyDayJSON: any) => {
                return StudyDay.fromJSON(studyDayJSON);
            })
        }));
    }

    public updateStudyDay(studyDay: StudyDay): Observable<void> {
        return this.httpClient.put(`${environment.apiUrl}/studydays/${studyDay.id}`, {
            signedUpForStudy: studyDay.signedUpForStudy,
            chosenMeal: studyDay.chosenMeal
        }).pipe(mapRxjs(() => {
            return;
        }));
    }

    public downloadStudyPlanningExcel(): Observable<any> {
        return this.httpClient.get(`${environment.apiUrl}/studydays`, {
            responseType: 'blob'
        });
    }
}