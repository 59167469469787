<section id="login-content">
    <figure>
        <img id="logo" src="assets/images/logo.svg"/>
    </figure>
    
    <article [formGroup]="loginForm" id="login-form">
        <mat-error *ngIf="loginInvalid">
            The username and password were not recognised
          </mat-error>
        <mat-form-field>
            <mat-label>Username</mat-label>
            <input formControlName="username" matInput>
        </mat-form-field>
        <mat-form-field >
            <mat-label>Password</mat-label>
            <input type="password" formControlName="password" matInput>
        </mat-form-field>
        <div id="login-button">
            <button mat-raised-button color="primary" (click)="login()">Inloggen</button>
        </div>
    </article>

   
</section>