import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthConfig, OAuthService } from "angular-oauth2-oidc";
import { isUndefined } from "lodash";
import { Observable, map as mapRxjs } from "rxjs";
import { PersistentStore, PERSISTENT_STORE } from "../application/helper/persistent-store";
import { AppEvent } from "../domain/app-event";
import { AppEventType } from "../domain/app-event-type";
import { User } from "../domain/user";
import { EventQueueService } from "./event-queue.service";
import { environment } from "src/environments/environment";

@Injectable()
export class AuthenticationService {
    private readonly persistentStore: PersistentStore;
    private readonly httpClient: HttpClient;
    private readonly oauthService: OAuthService;
    private readonly router: Router;
    private readonly eventQueue: EventQueueService;
    public static authConfig: AuthConfig = {
        loginUrl: 'https://tersterre-sgr26.smartschool.be/OAuth',
        redirectUri: encodeURI('https://zodiac.tersterre.be'),
        clientId: 'cc23d52bf935',
        responseType: 'code',
        scope: 'fulluserinfo',
        oidc: false,
        tokenEndpoint: 'https://oauth.smartschool.be/OAuth/index/token',
        disablePKCE: true
    };

    public constructor(
        @Inject(PERSISTENT_STORE) persistentStore: PersistentStore,
        httpClient: HttpClient,
        oauthService: OAuthService,
        router: Router,
        eventQueue: EventQueueService
    ) {
        this.persistentStore = persistentStore;
        this.httpClient = httpClient;
        this.oauthService = oauthService;
        this.router = router;
        this.eventQueue = eventQueue;
    }

    public getAuthenticatedUser(): User | null {
        let result = null;

        const user = this.persistentStore.get('user');
        if(!isUndefined(user)) {
          result = User.fromJSON(JSON.parse(<string>user))
        }

        return result;
    }

    public getAuthorizationToken(): string | undefined {
        return this.persistentStore.get('token');
    }

    public authenticateAsAdmin(username: string, password: string): Observable<void> {
        const decodedPassword: string = Buffer.from(password).toString('base64');

        return this.httpClient.post(`${environment.apiUrl}/authenticate-admin`, {
            username: username,
            password: decodedPassword
        }).pipe(mapRxjs((result) => {
            return;
        }));
    }

    public logout(): void {
        this.persistentStore.remove('user');
        this.oauthService.logOut();
        this.eventQueue.dispatch(new AppEvent(AppEventType.UserUpdated, null));
        this.router.navigateByUrl('/login');
    }
}