import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { CanActivate, Navigation, Router } from "@angular/router";
import { OAuthService, TokenResponse } from "angular-oauth2-oidc";
import { isNil, isUndefined } from "lodash";
import { AppEvent } from "src/app/domain/app-event";
import { AppEventType } from "src/app/domain/app-event-type";
import { User } from "src/app/domain/user";
import { AuthenticationService } from "src/app/infrastructure/authentication.service";
import { EventQueueService } from "src/app/infrastructure/event-queue.service";
import { PersistentStore, PERSISTENT_STORE } from "../helper/persistent-store";
import { environment } from "src/environments/environment";

@Injectable()
export class CanActivateViaAuthGuard implements CanActivate {
    private readonly persistentStore: PersistentStore;
    private readonly oauthService: OAuthService;
    private readonly router: Router;
    private readonly httpClient: HttpClient;
    private readonly eventQueue: EventQueueService;

    public constructor(
        @Inject(PERSISTENT_STORE) persistentStore: PersistentStore,
        oauthService: OAuthService,
        router: Router,
        httpClient: HttpClient,
        eventQueue: EventQueueService
        ) {
        this.persistentStore = persistentStore;
        this.oauthService = oauthService;
        this.router = router;
        this.httpClient = httpClient;
        this.eventQueue = eventQueue;
    }

    public canActivate(): boolean {
        const hasUserReference = !isUndefined(this.persistentStore.get('user'));
        
        if(!hasUserReference) {
            const navigation: Navigation | null = this.router.getCurrentNavigation();
            const authorizationCode = !isNil(navigation) && !isNil(navigation.extractedUrl) ? navigation.extractedUrl.queryParams['code'] : null;

            if (isNil(authorizationCode)) {
                this.router.navigateByUrl('/login');
            } else {
                //go to BE with authorizationcode

                //this.configureAuthFlowConfig();
                this.retrieveToken(authorizationCode);
            }
        }
        return hasUserReference;
    }

    private configureAuthFlowConfig(): void {
        this.oauthService.configure(AuthenticationService.authConfig);
    }

    private retrieveToken(authorizationCode: string): void {
        debugger;
        this.httpClient.get(`${environment.apiUrl}/authenticate?code=${authorizationCode}`)
                .subscribe({
                    next: (user: any) => {
                        console.log('HIP HIEP HOERA', user);
                    },
                    error: (error) => {

                    }
                });
        /*this.oauthService.fetchTokenUsingGrant('authorization_code', {
            code: authorizationCode,
            redirect_uri: encodeURI('https://zodiac.tersterre.be'),
            client_id: 'cc23d52bf935',
            client_secret: 'e0202fd676a2'
        }).then((token: TokenResponse) => {
            console.log('token', token);
            debugger;
            this.persistentStore.set('token', token.access_token);
            /*this.httpClient.get(`https://oauth.smartschool.be/Api/V1/${this.oauthService.scope}?access_token=${token.access_token}`)
                .subscribe({
                    next: (userInfo: any) => {
                        console.log(userInfo);

                        this.httpClient.get(`${environment.apiUrl}/users/${userInfo.username}/class`)
                            .subscribe({
                                next: (userClass: any) => {
                                    const user: User = new User(
                                        userInfo.name, 
                                        userInfo.surname, 
                                        userInfo.basisrol, 
                                        userInfo.username,
                                        userClass
                                    );
                                    this.persistentStore.set('user', JSON.stringify(user.toJSON()));
                                    this.eventQueue.dispatch(new AppEvent(AppEventType.UserUpdated, user));
                                    this.router.navigateByUrl('/');
                                }, error: (error) => {

                                }
                            })                        
                    }, error: (error) => {
                        console.error('user profile error', error);
                    }
                });*/
        /*}).catch((error) => {
            console.error('token err', error);
        });*/
    }
}